import React, {Component} from 'react'

import './Input.css'

const AUTOCOMPLETE_ATTRIBUTES = {
  phone: 'tel-national',
}

class Input extends Component {
  componentDidMount() {
    this.refs.input.addEventListener('change', this.handleNativeChange)
  }

  componentWillUnmount() {
    this.refs.input.removeEventListener('change', this.handleNativeChange)
  }

  handleNativeChange = ({target}) => {
    // https://github.com/facebook/react/issues/1159#issuecomment-371604044
    this.props.input.onChange(target.value)
  }

  render() {
    const {input, meta, type, label, multiline, ...props} = this.props

    let className = 'input'
    if (input.value || meta.active) {
      className += ' focus'
    }

    let Comp = 'input'
    if (multiline) {
      className += ' textarea'
      Comp = 'textarea'
    } else {
      input.type = type || 'text'
      input.autoComplete = AUTOCOMPLETE_ATTRIBUTES[input.name] || input.name
    }

    return (
      <div className={className}>
        <Comp {...input} {...props} ref="input" />
        <span>{label}</span>
      </div>
    )
  }
}

export default Input
