import React, {Component} from 'react'
import Helmet from 'react-helmet'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'

import config from './config'
import './App.css'

import Home from './Home'
import Order from './Order'
import Subscribe from './Subscribe'
import Thanks from './Thanks'
import Legal from './Legal'
import Footer from './Footer'

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Helmet>
            <title>{config.app.name} — Pain d’antan et bières artisanales à Toulouse</title>
          </Helmet>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/order/:eventId" component={Order} />
            <Route path="/subscribe" component={Subscribe} />
            <Route path="/thanks" component={Thanks} />
            <Route path="/legal" component={Legal} />
            <Redirect to="/" />
          </Switch>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App
