import {deadlineTime} from './utils'

const initialState = {
  error: null,
  events: null,
  products: null,
  places: null,
  settings: null,
}

export default (state = initialState, action = null) => {
  switch (action.type) {
    case 'DATA':
      if (action.name === 'events') {
        action.data.forEach((event) => {
          event.deadlineTime = deadlineTime(event.deadline, event.deadline_hour)
        })
      }
      return {
        ...state,
        [action.name]: action.data,
      }
    case 'SETTINGS':
      return {
        ...state,
        settings: action.data,
      }
    case 'ERROR':
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}
