import React, {Component} from 'react'
import {connect} from 'react-redux'
import Helmet from 'react-helmet'
import {reduxForm, Field, FieldArray, formValueSelector} from 'redux-form'

import './Order.css'

import Header from './Header'
import ProductsField from './fields/Products'
import PlaceField from './fields/Place'
import InputField from './fields/Input'
import CheckboxField from './fields/Checkbox'
import {MONTHS, formatAmount, orderValidator, orderFail, sortProduct} from './utils'
import {MODE, submitOrder, matchPartner} from './actions'
import config from './config'

class Order extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps(props) {
    if (props.submitting && !this.props.submitting) {
      window.scrollTo(0, 0)
    }
  }

  handleSubmit = (event) => {
    this.props.handleSubmit(submitOrder)(event)
  }

  renderContent = () => {
    const {places, total, loading, event, submitting, submitSucceeded, error, invalid, maxBreads, adultsOnly} = this.props

    if (submitSucceeded) {
      return (
        <section className="end thanks"><p>Merci pour votre commande !<br />Vous allez recevoir un e-mail de confirmation.</p></section>
      )
    }
    if (loading || submitting) {
      return (
        <section className="end loading"><div id="loader"><div id="arc" /></div></section>
      )
    }
    if (!event) {
      return (
        <section className="end error"><p>Cette vente n’existe pas</p></section>
      )
    }
    if (!maxBreads) {
      return (
        <section className="end error"><p>Désolé, cette vente est complète : le nombre maximum de pains est atteint</p></section>
      )
    }

    const remaining = Math.max(0, event.deadlineTime - Date.now())

    if (!remaining) {
      return (
        <section className="end error"><p>Nous sommes désolés mais cette vente est fermée.</p></section>
      )
    }

    const deadline = new Date(event.deadlineTime)
    const lastminute = remaining < (4 * 3600000) // after 6pm = 4 hours remaining

    return (
      <form className="order" onSubmit={this.handleSubmit}>
        <section className="content">
          {lastminute && <p className="warn">Attention, cette vente se termine à {deadline.getHours()}h !<br />Veuillez passer votre commande avant.</p>}
          <h2>Choisissez vos produits</h2>
          <FieldArray name="products" component={ProductsField} />
          {places && (
            <div>
              <h2>Choisissez votre point de livraison</h2>
              {places.map((place) => <Field key={place.id} name="place" component={PlaceField} place={place} />)}
            </div>
          )}
        </section>
        <section className="total">
          <div id="total"><span>Total :</span><br />{formatAmount(total)}</div>
        </section>
        <section className="customer">
          <Field name="name" component={InputField} label="Prénom Nom" required />
          <Field name="email" component={InputField} label="Adresse e-mail" type="email" required />
          <Field name="phone" component={InputField} label="Numéro de téléphone" required />
          <Field name="comment" component={InputField} label="Commentaires (facultatif)" multiline />
          {adultsOnly && <Field name="adult" component={CheckboxField} label="Je certifie avoir plus de 18 ans" />}
          <div className="submit">
            <button type="submit" disabled={(invalid && !error) || submitting}>Commander</button>
            <br />
            <span>
              <img src="/images/secure.png" alt="Paiement sécurisé" />
              Paiement sécurisé via Stripe
            </span>
          </div>
          <p className="error">{error}</p>
        </section>
      </form>
    )
  }

  render() {
    const {title} = this.props

    return (
      <div id="order-container">
        <Helmet>
          <meta name="robots" content="noindex, follow" />
          <title>{config.app.name} — {title}</title>
        </Helmet>
        <Header title={title} />
        <main>
          {this.renderContent()}
        </main>
      </div>
    )
  }
}

const selector = formValueSelector('order')

const mapStateToProps = (state, props) => {
  const {db} = state
  const {eventId} = props.match.params

  const productAmounts = selector(state, 'products')
  const getTotal = (filter) => productAmounts ? productAmounts.reduce((prev, {amount, price, type}) => ((!filter || type === filter) ? (prev + price * amount) : prev), 0) : 0

  const total = getTotal()
  const breadTotal = getTotal('bread')
  const otherTotal = getTotal('other')
  const beerTotal = total - breadTotal - otherTotal // beer + pack

  let maxBreads = 0
  let event
  let places
  if (db.events) {
    event = db.events.find(({id}) => id === eventId)
    if (event) {
      if (db.places && total > 0) {
        places = db.places.filter(({types, id, partners}) => {
          if (!types.deposit) {
            return false
          }
          if (!matchPartner(partners)) {
            return false
          }
          if (breadTotal > 0 && !(event.bread_places && event.bread_places[id])) {
            return false
          }
          if (beerTotal > 0 && !(event.beer_places && event.beer_places[id])) {
            return false
          }
          if (otherTotal > 0 && !(event.other_places && event.other_places[id])) {
            return false
          }
          return true
        })
      }
      if (db.settings) {
        maxBreads = db.settings.order.max_bread_units_per_event - (event['orderedBreads' + (MODE === 'test' ? '_test' : '')] || 0)
      }
    }
  }

  const productFilter = ({id, availability}) => {
    if (availability === 'disabled') {
      return false
    }
    if (availability === 'temporary') {
      if (!event) {
        return false // loading
      }
      if (!event.products[id]) {
        return false
      }
    }
    return true
  }
  const products = db.products ? db.products.filter(productFilter).map((product) => ({...product, amount: 0})) : []

  products.sort(sortProduct)

  const date = new Date(eventId)
  const title = `Fournée du ${date.getDate()} ${MONTHS[date.getMonth()].replace('û', 'u')} ${date.getFullYear()}`

  return {
    title,
    loading: !(db.events && db.settings),
    places,
    maxBreads,
    total,
    initialValues: {
      products,
    },
    event,
    adultsOnly: beerTotal > 0,
  }
}

const formParams = {
  form: 'order',
  enableReinitialize: true,
  //keepDirtyOnReinitialize: true,
  validate: orderValidator,
  onSubmitFail: orderFail,
}

export default connect(mapStateToProps)(reduxForm(formParams)(Order))
