import React, {Component} from 'react'
import {MdRemoveCircle, MdAddCircle} from 'react-icons/md'

import {formatAmount, formatWeight} from '../utils'

class ProductField extends Component {
  handleSub = (event) => {
    event.preventDefault()
    const {input: {value, onChange}} = this.props
    if (value.amount > 0) {
      onChange({
        ...value,
        amount: value.amount - 1,
      })
    }
  }

  handleAdd = (event) => {
    event.preventDefault()
    const {input: {value, onChange}} = this.props
    onChange({
      ...value,
      amount: value.amount + 1,
    })
  }

  render() {
    const {title, price, weight, description, photo, amount, type} = this.props.input.value

    return (
      <div className="product">
        <div className="photo" style={photo && {backgroundImage: `url(${photo})`}} />
        <div className="content">
          <div className="description">
            <h3>{title}</h3>
            <p>{description}</p>
            <p className="price">{formatAmount(price)} / {formatWeight(weight, type)}</p>
          </div>
          <div className="amount">
            <button onClick={this.handleSub} className={amount ? null : 'disabled'}>
              <MdRemoveCircle size={30} />
            </button>
            <span>{amount}</span>
            <button onClick={this.handleAdd}>
              <MdAddCircle size={30} />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductField
