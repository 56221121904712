import 'react-app-polyfill/ie11' // Also for Safari mobile 7

import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {createStore, combineReducers, applyMiddleware} from 'redux'
import {reducer as formReducer} from 'redux-form'
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'

import appReducer from './reducer'
import listen from './listen'
import App from './App'

const rootReducer = combineReducers({
  db: appReducer,
  form: formReducer,
})

let store
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    errorTransformer: (error) => {
      console.error(error) // eslint-disable-line no-console
      return error
    },
    collapsed: true,
  })
  store = createStore(rootReducer, applyMiddleware(thunk, logger))
} else {
  store = createStore(rootReducer, applyMiddleware(thunk))
}

store.dispatch(listen())

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'))
