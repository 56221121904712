import React, {Component} from 'react'
import {MdCheckBox, MdCheckBoxOutlineBlank} from 'react-icons/md'

import './Checkbox.css'

class Checkbox extends Component {
  handleClick = (event) => {
    event.preventDefault()
    const {input: {value, onChange}} = this.props
    onChange(!value)
  }

  render() {
    const {input: {value}, label} = this.props

    return (
      <button onClick={this.handleClick} className="checkbox">
        {value ? <MdCheckBox size={24} /> : <MdCheckBoxOutlineBlank size={24} />}
        <span>{label}</span>
      </button>
    )
  }
}

export default Checkbox
