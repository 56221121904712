import config from './config'

let callbacks = []
let loaded = false

window.initMaps = () => {
  loaded = true
  if (callbacks.length) {
    callbacks.forEach((callback) => callback())
    callbacks = []
  }
}

export default (callback) => {
  if (loaded) {
    callback()
  } else {
    callbacks.push(callback)
  }
}

const tag = document.createElement('script')
tag.async = true
tag.src = 'https://maps.googleapis.com/maps/api/js?libraries=places,geometry&language=fr&callback=initMaps&key=' + config.google.maps_key
document.head.appendChild(tag)
