import React, {Component} from 'react'
import {MdRemoveCircle, MdAddCircle} from 'react-icons/md'

import './Duration.css'

class DurationField extends Component {
  handleSub = (event) => {
    event.preventDefault()
    const {input: {value, onChange}, min} = this.props
    if (value > min) {
      onChange(value - 1)
    }
  }

  handleAdd = (event) => {
    event.preventDefault()
    const {input: {value, onChange}, max} = this.props
    if (value < max) {
      onChange(value + 1)
    }
  }

  render() {
    const {input: {value}, min, max} = this.props

    return (
      <div className="duration">
        <span>Pendant</span>
        <button onClick={this.handleSub} className={value === min ? 'disabled' : null}>
          <MdRemoveCircle size={30} />
        </button>
        <span className="value">{value}</span>
        <button onClick={this.handleAdd} className={value === max ? 'disabled' : null}>
          <MdAddCircle size={30} />
        </button>
        <span>semaines</span>
      </div>
    )
  }
}

export default DurationField
