import config from './config'

const API_URL = config.api[window.location.hostname === 'localhost' ? 'local' : 'live']

const post = (route, params) => {
  return fetch(API_URL + '/' + route, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
  .catch(() => {
    throw new Error('La requête n’a pas pu aboutir. Veuillez vérifier votre connexion.')
  })
  .then((response) => {
    if (response.status >= 500) {
      throw new Error('Le serveur a renvoyé une erreur. Veuillez réessayer.')
    }
    // if status >= 400, the error will be in the returned JSON and is handled by the component
    return response.json()
  })
  .then((response) => {
    if (response.error) {
      throw new Error(response.error)
    }
    return response
  })
}

export default {post}
