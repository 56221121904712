import {db, toArray, documentId} from './firebase'
import {dateStr} from './utils'

const today = new Date()
const events = db.collection('events').where(documentId(), '>=', dateStr(today))
const products = db.collection('products')
const places = db.collection('places')
const settings = db.collection('meta').doc('settings')

export default () => {
  return (dispatch) => {
    const onError = (error) => {
      dispatch({
        type: 'ERROR',
        error,
      })
      if (window.Rollbar) {
        Rollbar.error(error) /*global Rollbar:false*/
      } else {
        console.error(error) // eslint-disable-line no-console
      }
    }
    events.onSnapshot((snapshot) => {
      dispatch({
        type: 'DATA',
        name: 'events',
        data: toArray(snapshot),
      })
    }, onError)
    products.onSnapshot((snapshot) => {
      dispatch({
        type: 'DATA',
        name: 'products',
        data: toArray(snapshot),
      })
    }, onError)
    places.onSnapshot((snapshot) => {
      dispatch({
        type: 'DATA',
        name: 'places',
        data: toArray(snapshot),
      })
    }, onError)
    settings.onSnapshot((snapshot) => {
      dispatch({
        type: 'SETTINGS',
        data: snapshot.data(),
      })
    }, onError)
  }
}
