import jump from 'jump.js'
import {clearSubmitErrors} from 'redux-form'

const SMOOTH_DURATION = 500

let scrolling

const end = () => {
  scrolling = false
}

export const scroll = (to, offset, immediate) => {
  if (scrolling) {
    return
  }
  if (!immediate) {
    scrolling = true
    setTimeout(end, SMOOTH_DURATION)
  }
  const target = document.querySelector(to)
  if (!target) {
    throw new Error('scroll target not found: ' + to)
  }
  jump(target, {
    duration: immediate ? 0 : SMOOTH_DURATION,
    offset,
  })
}

export const toArray = (obj, sortBy, reverse) => {
  const arr = []
  if (!obj) {
    return arr
  }
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const item = obj[key]
      if (typeof item === 'boolean') {
        arr.push(key)
      } else {
        if (typeof item === 'object') {
          item.key = key
        }
        arr.push(item)
      }
    }
  }
  if (sortBy) {
    const sup_value = reverse ? -1 : 1
    const inf_value = reverse ? 1 : -1
    arr.sort((a, b) => a[sortBy] > b[sortBy] ? sup_value : inf_value)
  }
  return arr
}

export const isMobile = () => (window.innerWidth < 500)

export const formatAmount = (amount) => (amount / 100).toFixed(2).replace('.', ',') + ' €'

export const formatWeight = (weight, type) => {
  if (type === 'bread') {
    return weight < 1000 ? `${weight} g` : `${weight / 1000} kg`.replace('.', ',')
  }
  if (type === 'beer') {
    return weight < 100 ? `${weight} cl` : `${weight / 100} L`.replace('.', ',')
  }
  if (type === 'other') {
    return 'pièce'
  }
  return weight // packs
}

export const DAYS = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
export const MONTHS = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
export const ONE_DAY = 86400000 // ms

export const deadlineTime = (deadline, time) => {
  const date = new Date(deadline)
  date.setHours((typeof time === 'number') ? time : 22) // default to 10pm
  return date.getTime()
}

const pad2 = (n) => (n > 9) ? String(n) : ('0' + n)
export const dateStr = (date) => `${date.getFullYear()}-${pad2(date.getMonth() + 1)}-${pad2(date.getDate())}`

export const orderValidator = (values, props) => {
  const countOfType = (type) => values.products ? values.products.reduce((prev, curr) => (((!type || curr.type === type) ? curr.amount : 0) + prev), 0) : 0
  const numBreads = countOfType('bread')
  const total = countOfType()

  const errors = {}
  if (!total) {
    errors.products = 'Vous devez choisir au moins un produit'
  }
  if (!values.place) {
    errors.place = 'Vous devez choisir un point de retrait'
  }
  if (!values.name) {
    errors.name = 'Veuillez renseigner votre nom'
  }
  if (!values.email) {
    errors.email = 'Veuillez renseigner votre adresse e-mail'
  }
  if (!values.phone) {
    errors.phone = 'Veuillez renseigner votre numéro de téléphone'
  }
  if (numBreads > props.maxBreads) {
    errors._error = `Désolé, il ne reste que ${props.maxBreads} pains disponibles`
    //TODO: same for beers?
  }
  if (total > numBreads && !values.adult) {
    errors.adult = 'La vente de bière est réservée aux adultes'
  }
  // for subscriptions:
  if (values.days) {
    const numDays = values.days.reduce((prev, {selected}) => selected ? (prev + 1) : prev, 0)
    if (!numDays) {
      errors.days = 'Vous devez choisir au moins un jour de livraison'
    }
    if (!values.duration) {
      errors.duration = 'Vous devez choisir une durée'
    }
  }
  return errors
}

export const orderFail = (errors, dispatch) => {
  if (errors._error === 'user_cancelled') {
    dispatch(clearSubmitErrors('order'))
  }
  window.scrollTo(0, document.body.scrollHeight) // to see the error
}

export const PRODUCT_ORDER = {
  bread: 1,
  beer: 2,
  pack: 3,
  other: 4,
}

export const sortByPosition = (left, right) => (left.position || 0) - (right.position || 0)

export const sortProduct = (left, right) => (PRODUCT_ORDER[left.type] * 1000 + (left.position || 0)) - (PRODUCT_ORDER[right.type] * 1000 + (right.position || 0))

/*global DocumentTouch:false*/
export const isTouchDevice = () => (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch))
