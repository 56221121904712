import React, {Component} from 'react'
import {connect} from 'react-redux'
import Helmet from 'react-helmet'
import {reduxForm, Field, FieldArray, formValueSelector} from 'redux-form'

import './Order.css'

import Header from './Header'
import ProductsField from './fields/Products'
import PlaceField from './fields/Place'
import InputField from './fields/Input'
import CheckboxField from './fields/Checkbox'
import DaysField from './fields/Days'
import DurationField from './fields/Duration'
import {formatAmount, orderValidator, orderFail, sortProduct} from './utils'
import {submitSubscribe, matchPartner, PARTNER} from './actions'
import config from './config'

class Subscribe extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps(props) {
    if (props.submitting && !this.props.submitting) {
      window.scrollTo(0, 0)
    }
  }

  handleSubmit = (event) => {
    this.props.handleSubmit(submitSubscribe)(event)
  }

  renderContent = () => {
    const {places, total, loading, submitting, submitSucceeded, error, invalid, maxBreads, minDuration, maxDuration, adultsOnly} = this.props

    if (submitSucceeded) {
      return (
        <section className="end thanks"><p>Merci pour votre commande !<br />Vous allez recevoir un e-mail de confirmation.</p></section>
      )
    }
    if (loading || submitting) {
      return (
        <section className="end loading"><div id="loader"><div id="arc" /></div></section>
      )
    }
    if (!maxBreads) {
      return (
        <section className="end error"><p>Désolé, cette vente est complète : le nombre maximum de pains est atteint</p></section>
      )
    }

    return (
      <form className="order" onSubmit={this.handleSubmit}>
        <section className="content">
          <h2>Choisissez vos produits</h2>
          <FieldArray name="products" component={ProductsField} />
          <h2>Choisissez vos jours de livraison</h2>
          <FieldArray name="days" component={DaysField} />
          <Field name="duration" component={DurationField} min={minDuration} max={maxDuration} />
          {places && (
            <div>
              <h2>Choisissez votre point de livraison</h2>
              {places.map((place) => <Field key={place.id} name="place" component={PlaceField} place={place} />)}
            </div>
          )}
        </section>
        <section className="total">
          <div id="total"><span>Total :</span><br />{formatAmount(total)}</div>
        </section>
        <section className="customer">
          <Field name="name" component={InputField} label="Prénom Nom" required />
          <Field name="email" component={InputField} label="Adresse e-mail" type="email" required />
          <Field name="phone" component={InputField} label="Numéro de téléphone" required />
          <Field name="comment" component={InputField} label="Commentaires (facultatif)" multiline />
          {adultsOnly && <Field name="adult" component={CheckboxField} label="Je certifie avoir plus de 18 ans" />}
          <div className="submit">
            <button type="submit" disabled={(invalid && !error) || submitting}>S’abonner</button>
            <br />
            <span>
              <img src="/images/secure.png" alt="Paiement sécurisé" />
              Paiement sécurisé via Stripe
            </span>
          </div>
          <p className="error">{error}</p>
        </section>
      </form>
    )
  }

  render() {
    const {title} = this.props

    return (
      <div id="order-container">
        <Helmet>
          <meta name="robots" content={PARTNER === '_public' ? 'index, follow' : 'noindex'} />
          <title>{config.app.name} — {title}</title>
        </Helmet>
        <Header title={title} />
        <main>
          {this.renderContent()}
        </main>
      </div>
    )
  }
}

const selector = formValueSelector('order')

const productFilter = ({availability, type}) => {
  if (availability === 'disabled') {
    return false
  }
  if (availability === 'temporary') {
    return false
  }
  if (type === 'other') {
    return false
  }
  return true
}

const mapStateToProps = (state, props) => {
  const {db} = state

  const productAmounts = selector(state, 'products')
  const getTotal = (filter) => productAmounts ? productAmounts.reduce((prev, {amount, price, type}) => ((!filter || type === filter) ? (prev + price * amount) : prev), 0) : 0

  const dailyTotal = getTotal()
  const breadTotal = getTotal('bread')
  const beerTotal = dailyTotal - breadTotal // beer + pack

  const selectedDays = selector(state, 'days')
  const duration = selector(state, 'duration')

  const maxBreads = (db.settings && db.settings.order.max_bread_units_per_event) || 0
  // TODO: find out maxBreads checking the next ${day} events during ${duration} weeks
  // if (db.events) {
  //   add (event['orderedBreads' + (MODE === 'test' ? '_test' : '')] || 0)
  // }

  let places
  if (db.places && selectedDays && selectedDays.some(({selected}) => selected)) {
    places = db.places.filter(({types, breadDays, beerDays, partners}) => {
      return types.deposit && selectedDays.every(({day, selected}) => {
        if (!selected) {
          return true
        }
        if (breadTotal > 0 && (!breadDays || !breadDays[day])) {
          return false
        }
        if (beerTotal > 0 && (!beerDays || !beerDays[day])) {
          return false
        }
        if (!matchPartner(partners)) {
          return false
        }
        return true
      })
    })
  }

  const products = db.products ? db.products.filter(productFilter).map((product) => ({...product, amount: 0})) : []
  const days = []
  if (db.settings) {
    products.push({
      id: 'surprise',
      type: 'bread',
      position: 500,
      price: db.settings.subscribe.random_bread_price,
      title: 'Pain de saison',
      description: 'En fonction de l’humeur du boulanger',
      weight: db.settings.subscribe.random_bread_weight,
      photo: 'https://firebasestorage.googleapis.com/v0/b/brewlangerie.appspot.com/o/products%2FzVNZkudXJyq8bPGTXUxx%2FBetterave-Sesame.jpg?alt=media&token=0b2310c4-3ea6-4207-bbde-9c3710ba0437',
      amount: 0,
    })

    const daysMap = {}
    if (db.places) {
      db.places.forEach(({types, partners, breadDays, beerDays}) => {
        if (!types.deposit || !matchPartner(partners)) {
          return
        }
        for (const day in breadDays) {
          if (breadDays[day]) {
            daysMap[day] = true
          }
        }
        for (const day in beerDays) {
          if (beerDays[day]) {
            daysMap[day] = true
          }
        }
      })
    }
    for (const day in daysMap) {
      days.push({
        day: Number(day),
        selected: false,
      })
    }
    // make sure the browser's object keys ordering isn't tricking us:
    days.sort((day1, day2) => day1.day > day2.day ? 1 : -1)
  }

  products.sort(sortProduct)

  let minDuration
  let maxDuration
  if (db.settings && db.settings.subscribe) {
    minDuration = db.settings.subscribe.min_duration_weeks
    maxDuration = db.settings.subscribe.max_duration_weeks
  }

  let total = 0
  if (selectedDays && duration) {
    const numDays = selectedDays.reduce((prev, {selected}) => selected ? (prev + 1) : prev, 0)
    total = dailyTotal * duration * numDays
    // console.log(`total = ${dailyTotal / 100} x ${duration} x ${numDays} = ${total / 100} €`)
  }

  const adultsOnly = productAmounts && productAmounts.some((product) => product.amount > 0 && product.type !== 'bread')

  return {
    title: 'S’abonner',
    loading: !db.settings,
    places,
    maxBreads,
    total,
    initialValues: {
      products,
      days,
      duration: maxDuration,
    },
    minDuration,
    maxDuration,
    adultsOnly,
  }
}

const formParams = {
  form: 'order',
  enableReinitialize: true,
  //keepDirtyOnReinitialize: true,
  validate: orderValidator,
  onSubmitFail: orderFail,
}

export default connect(mapStateToProps)(reduxForm(formParams)(Subscribe))
