import React, {Component} from 'react'
import {reduxForm, Field} from 'redux-form'

import InputField from './fields/Input'
import {submitMessage} from './actions'

import './Contact.css'

class Contact extends Component {
  handleSubmit = (event) => {
    this.props.handleSubmit(submitMessage)(event)
  }

  render() {
    const {submitting, submitSucceeded, submitFailed, error, invalid} = this.props

    if (submitSucceeded) {
      return (
        <div className="sent">Votre message a bien été envoyé, merci.</div>
      )
    }

    return (
      <form onSubmit={this.handleSubmit} className="contact">
        <Field
          name="name"
          component={InputField}
          label="Prénom Nom"
          required
        />
        <Field
          name="email"
          component={InputField}
          label="Adresse e-mail"
          type="email"
          required
        />
        <Field
          name="phone"
          component={InputField}
          label="Numéro de téléphone"
          required
        />
        <Field
          name="message"
          component={InputField}
          label="Votre message"
          multiline
          required
        />
        <div className="error">{error}</div>
        <button type="submit" disabled={(invalid || submitting) && !submitFailed}>Envoyer</button>
      </form>
    )
  }
}

const formParams = {
  form: 'contact',
  validate: (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Veuillez renseigner votre nom'
    }
    if (!values.email) {
      errors.email = 'Veuillez renseigner votre adresse e-mail'
    }
    if (!values.phone) {
      errors.phone = 'Veuillez renseigner votre numéro de téléphone'
    }
    if (!values.message) {
      errors.message = 'Veuillez renseigner votre message'
    }
    return errors
  },
}

export default reduxForm(formParams)(Contact)
