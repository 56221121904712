import firebase from 'firebase/app'
import 'firebase/firestore'

import config from './config'

const app = firebase.initializeApp(config.firebase)

export const db = app.firestore()
export const documentId = firebase.firestore.FieldPath.documentId

export const toArray = (snapshot) => {
  const data = []
  snapshot.forEach((doc) => {
    data.push({id: doc.id, ...doc.data()})
  })
  return data
}
