import React from 'react'
import {Link, withRouter} from 'react-router-dom'

import './Footer.css'

const Footer = ({location}) => {
  if (location.pathname === '/legal') {
    return null
  }

  return (
    <footer>
      <Link to="/legal">Mentions légales et conditions générales de vente</Link>
    </footer>
  )
}

export default withRouter(Footer)
