import React, {Component} from 'react'
import Helmet from 'react-helmet'

import './Thanks.css'

import Header from './Header'

class Thanks extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div id="order-container">
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <Header title="Merci" />
        <main>
          <section className="thanks"><p>Merci pour votre commande !<br />Vous allez recevoir un e-mail de confirmation.</p></section>
        </main>
      </div>
    )
  }
}

export default Thanks
