import React, {Component} from 'react'
import {connect} from 'react-redux'
import {formValueSelector} from 'redux-form'
import {MdCheckBox, MdCheckBoxOutlineBlank} from 'react-icons/md'

import {DAYS, MONTHS, ONE_DAY, dateStr} from '../utils'

class DayField extends Component {
  handleClick = (event) => {
    event.preventDefault()
    const {input: {value, onChange}, date} = this.props
    onChange({
      day: value.day,
      selected: !value.selected,
      start: dateStr(date),
    })
  }

  render() {
    const {input: {value: {day, selected}}, date} = this.props

    return (
      <button onClick={this.handleClick} className="day">
        {selected ? <MdCheckBox size={24} /> : <MdCheckBoxOutlineBlank size={24} />}
        <span>{DAYS[day - 1]}, à partir du {date.getDate()} {MONTHS[date.getMonth()]}</span>
      </button>
    )
  }
}

const selector = formValueSelector('order')

const mapStateToProps = (state, props) => {
  const firstDay = selector(state, 'days')[0].day

  const min = new Date()
  min.setTime(min.getTime() + ((min.getHours() < 22 ? 2 : 3) * ONE_DAY))

  const now = Date.now()

  const deadlined = (date) => {
    const dateId = dateStr(date)
    const event = state.db.events.find(({id}) => (id === dateId))
    if (event) {
      return event.deadlineTime < now
    }
    return date.getTime() < min.getTime()
  }

  const date = new Date()
  // start at the coming weekday that is the same weekday than the first option
  // then make sure it's in two days
  // then make sure the corresponding event's deadline (if any) is not passed
  while ((date.getDay() || 7) !== firstDay || deadlined(date)) {
    date.setTime(date.getTime() + ONE_DAY) // check next day
  }
  // now we have the first weekday date.
  // seek to the field's weekday if this is not the first field:
  while ((date.getDay() || 7) < props.input.value.day) {
    date.setTime(date.getTime() + ONE_DAY) // check next day
  }

  return {date}
}

export default connect(mapStateToProps)(DayField)
