import React, {Component} from 'react'
import {Field} from 'redux-form'

import './Days.css'
import DayField from './Day'

class DaysField extends Component {
  render() {
    const {fields} = this.props

    return (
      <div className="days">
        <p>Tous les</p>
        {
          fields.map((name, index) => (
            <Field key={index}
              name={name}
              component={DayField}
            />
          ))
        }
      </div>
    )
  }
}

export default DaysField
