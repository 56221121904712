import {SubmissionError} from 'redux-form'
import config from './config'
import api from './api'

export const submitMessage = (values) => {
  return api.post('contact', values)
  .catch((error) => {
    throw new SubmissionError({_error: error.message})
  })
}

const urlparts = window.location.hostname.split('.')

export const MODE = (urlparts[0] === 'test' || urlparts[0] === 'localhost') ? 'test' : 'live'
export const PARTNER = (MODE === 'live' && urlparts.length === 3 && urlparts[0] !== 'www') ? urlparts[0] : '_public'
const DEFAULT_PARTNERS = {_public: true}
export const matchPartner = (partners = DEFAULT_PARTNERS) => partners[PARTNER]

const STRIPE_KEY = config.stripe[MODE]
let stripe

/*global Stripe:false*/

export const submitOrder = (values, dispatch, props) => {
  if (!stripe) {
    // eslint-disable-next-line new-cap
    stripe = Stripe(STRIPE_KEY)
  }

  const products = values.products
  .filter(({amount}) => amount > 0)
  .map(({id, amount}) => ({id, amount}))

  const payload = {
    ...values,
    mode: MODE,
    total: props.total,
    products,
    date: props.event.id,
  }

  return api.post('order', payload)
  .catch((error) => {
    throw new SubmissionError({_error: error.message})
  })
  .then((session) => stripe.redirectToCheckout(session))
}

export const submitSubscribe = (values, dispatch, props) => {
  if (!stripe) {
    // eslint-disable-next-line new-cap
    stripe = Stripe(STRIPE_KEY)
  }

  const products = values.products
  .filter(({amount}) => amount > 0)
  .map(({id, amount}) => ({id, amount}))

  const days = values.days
  .filter(({selected}) => selected)
  .map(({day, start}) => ({day, start}))

  const payload = {
    ...values,
    mode: MODE,
    total: props.total,
    products,
    days,
  }

  return api.post('subscribe', payload)
  .catch((error) => {
    throw new SubmissionError({_error: error.message})
  })
  .then((session) => stripe.redirectToCheckout(session))
}
