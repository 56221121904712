import React, {Component} from 'react'
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md'

import './Place.css'

class PlaceField extends Component {
  handleClick = (event) => {
    event.preventDefault()
    const {place, input} = this.props
    input.onChange(place.id)
  }

  render() {
    const {place: {id, title, address, description}, input} = this.props

    return (
      <button onClick={this.handleClick} className="place">
        {input.value === id ? <MdRadioButtonChecked size={24} /> : <MdRadioButtonUnchecked size={24} />}
        <div id="description">
          <h3>{title}</h3>
          <p>{address}</p>
          <p className="hours">{description}</p>
        </div>
      </button>
    )
  }
}

export default PlaceField
