import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {scroll, isMobile} from './utils'

import './Header.css'

class Header extends Component {
  state = {
    showMenu: false,
  }

  handleScroll(target) {
    this.handleMenu(false, () => {
      scroll('#' + target, isMobile() ? 0 : -100)
    })
  }

  handleMenu = (show, callback) => {
    const showMenu = ((typeof show === 'boolean') ? show : (!this.state.showMenu))
    //document.body.style.position = showMenu ? 'fixed' : null //TODO: check iPhone 4
    this.setState({
      showMenu,
    }, callback)
  }

  facebook = (<a href="https://www.facebook.com/labrewlangerie/" className="facebook" target="_blank" rel="noopener noreferrer">Facebook</a>)
  instagram = (<a href="https://www.instagram.com/la_brewlangerie/" className="instagram" target="_blank" rel="noopener noreferrer">Instagram</a>)
  phone = (<button onClick={this.handleScroll.bind(this, 'contact')} className="phone">Téléphone</button>)
  places = (<button onClick={this.handleScroll.bind(this, 'places')} className="places">Localisation</button>)
  concept = (<button onClick={this.handleScroll.bind(this, 'concept')}>Concept</button>)
  products = (<button onClick={this.handleScroll.bind(this, 'products')}>Nos produits</button>)
  order = (<button onClick={this.handleScroll.bind(this, 'order')}>Commander</button>)
  findus = (<button onClick={this.handleScroll.bind(this, 'places')}>Nous trouver</button>)

  render() {
    const {title} = this.props
    const {showMenu} = this.state

    const logo = <img alt="logo" src="/logo.jpg" />

    if (title) {
      return (
        <header>
          <Link to="/" id="logo">
            {logo}
          </Link>
          <div id="shortcuts" className="social">
            {this.facebook}
            {this.instagram}
          </div>
          <h1>{title}</h1>
        </header>
      )
    }

    return (
      <header className="fixed">
        <button onClick={this.handleScroll.bind(this, 'home-container')} id="logo">
          {logo}
        </button>
        <div id="shortcuts" className="social">
          {this.facebook}
          {this.instagram}
          {this.phone}
          {this.places}
          <img src="/images/bio.png" alt="FR-BIO-12" />
        </div>
        <nav>
          {this.concept}
          {this.products}
          {this.order}
        </nav>
        <button id="hamburger" onClick={this.handleMenu} className={showMenu ? 'open' : ''}>
          <span /><span /><span />
        </button>
        <div id="overlay" className={showMenu ? 'open' : ''} />
        <div id="menu" className={showMenu ? 'open' : ''}>
          {this.concept}
          {this.products}
          {this.order}
          {this.findus}
          <div className="social">
            {this.facebook}
            {this.instagram}
            {this.phone}
          </div>
        </div>
      </header>
    )
  }
}

export default Header
