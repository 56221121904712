import React, {Component} from 'react'
import {Field} from 'redux-form'

import './Products.css'
import ProductField from './Product'

class ProductsField extends Component {
  render() {
    const {fields} = this.props

    return (
      <div>
        {
          fields.map((name, index) => (
            <Field key={index}
              name={name}
              component={ProductField}
            />
          ))
        }
      </div>
    )
  }
}

export default ProductsField
